import { Component } from '@angular/core';
import { CognitoService } from 'src/app/cognito.service'; 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  constructor(
    private authservice: CognitoService
  ) {}

  logOut() {
    this.authservice.logOut();
  }
}
