import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from 'src/app/cognito.service'; 
@Component({
  selector: 'app-new-password-require',
  templateUrl: './new-password-require.component.html',
  styleUrls: ['./new-password-require.component.scss']
})
export class NewPasswordRequireComponent {
  currentpassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  showConfirmPassword: any;
  showNewPassword: any;
  passwordRequirementsVisible: boolean = false;
  secondPasswordTyped: boolean = false;
  passwordError: boolean = false;
  validateError: boolean = false;
  successModal: boolean = false;
  loading: boolean = false;
  unMatchPassword: boolean = false;

  constructor(
    private authService: CognitoService, 
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.passwordChange$.subscribe((success: boolean) => {
      if (!success) {
        this.validateError = true;
      } else { 
        this.successModal = true;
      } 
    });
  }

  passwordsMatch(): boolean {
    return this.newPassword === this.confirmPassword;
  }

  redirectToLoginPage(): void {
    this.router.navigate(["/login"]);
    this.successModal = false;
  }

  togglePasswordRequirements() {
    this.passwordRequirementsVisible = !this.passwordRequirementsVisible;
  }


  checkInputs() {
    if (!this.newPassword || !this.confirmPassword) {
        this.secondPasswordTyped = false;
    } else {
        this.secondPasswordTyped = true;
    }
}

  validatePassword(password: string): boolean {
    if (password.length < 8) {
      return false;
    }

    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numericRegex = /[0-9]/;
    
    if (!lowercaseRegex.test(password) || !uppercaseRegex.test(password) || !specialCharRegex.test(password) || !numericRegex.test(password)) {
      this.passwordError = true;
      return false;
    }
    this.passwordError = false;
    return true;
  }

  confirmPasswordReset() {
    this.unMatchPassword = !this.passwordsMatch(); 
    if (this.unMatchPassword) {
      this.passwordError = false; 
      return; 
    }
    if (!this.validatePassword(this.newPassword)) {
      this.passwordError = true; 
      return; 
    }
    this.authService.changePassword(this.currentpassword, this.newPassword, this.confirmPassword);
  }
    
}