import { Injectable } from '@angular/core';  
import { CognitoService } from './cognito.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private cognitoService: CognitoService) { }
    isAuth(): boolean {
        const token = this.cognitoService.token; 
        return this.hasToken(token); 
    }
    private hasToken(token: string): boolean {
        return !!token; 
    }
}
