<div class="font-sans">
  <div class="relative min-h-screen p-10 flex flex-col sm:justify-center items-center bg-gray-300 ">
    <div class="relative sm:max-w-sm w-full">
      <div class="card bg-custom-red shadow-lg w-full h-full rounded-3xl absolute transform -rotate-6"></div>
      <div class="card bg-custom-blue shadow-lg w-full h-full rounded-3xl absolute transform rotate-6"></div>
      <div class="relative w-full rounded-3xl  px-6 py-4 bg-gray-100 shadow-md">
        <div class="flex mt-7 items-center text-center">
          <hr class="border-gray-300 border-1 w-32 rounded-md">
          <div class="-mx-6 px-6 py-4">
            <a href="#" title="home">
              <img src="https://mobydyg.com/assets/logo-large.svg" class="w-full" alt="tailus logo">
            </a>
          </div>
          <hr class="border-gray-300 border-1 w-32 rounded-md">
        </div>

        <form (submit)="onSignIn(signInForm)" #signInForm="ngForm" class="mt-10">
          <div>
            <input type="email" placeholder="Correo electrónico" class="mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0" id="email" name="email" ngModel #emailInput="ngModel" [(ngModel)]="emailaddress" style="padding-left: 15px;">
          </div>

          <div class="mt-7">
            <label for="password" class="block text-sm mb-2">Contraseña:</label>
            <div class="relative">
              <input [type]="showPassword ? 'text' : 'password'" id="password" name="password" #passwordInput="ngModel" [(ngModel)]="password" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" placeholder="Contraseña" style="padding-left: 15px;">
              <button type="button" class="absolute top-0 end-0 p-3.5 rounded-e-md" (click)="showPassword = !showPassword">
                <svg class="flex-shrink-0 size-3.5 text-gray-400" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path *ngIf="showPassword" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                  <path *ngIf="showPassword" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                  <path *ngIf="showPassword" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                  <line *ngIf="showPassword" x1="2" x2="22" y1="2" y2="22"></line>
                  <path *ngIf="!showPassword" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                  <circle *ngIf="!showPassword" cx="12" cy="12" r="3"></circle>
                </svg>
              </button>
              
            </div>
          </div>
  
          <div class="font-regular relative block w-full rounded-lg bg-rose-500 p-3 mt-4 text-base leading-5 text-white opacity-100" *ngIf="showErrorAlert">
            <div class="mr-12">Usuario y/o contraseña incorrectos</div>
            <div class="absolute top-2.5 right-3 w-max rounded-lg transition-all hover:bg-white hover:bg-opacity-20">
              <button role="button" class="w-max rounded-lg p-1" (click)="showErrorAlert = false">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          </div>
          <div class="mt-7">
            <button type="submit" class="bg-custom-blue w-full py-3 rounded-xl text-white text-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
              Iniciar sesión
            </button>
          </div>
        </form>
        <!-- Fin del formulario de inicio de sesión -->
      </div>
    </div>
  </div>
</div>
