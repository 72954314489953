import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CognitoService } from 'src/app/cognito.service';  

@Component({
  selector: 'app-mfa-verification',
  templateUrl: './mfa-verification.component.html',
  styleUrls: ['./mfa-verification.component.scss']
})
export class MfaVerificationComponent implements OnInit {
  verificationForm!: FormGroup;

  constructor(private cognitoService: CognitoService) { }

  ngOnInit(): void {
    this.verificationForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
    });
  }

  onSubmit() {
/*     if (this.verificationForm.valid) {
      const code = this.verificationForm.value.code;
      this.cognitoService.verifySMSCode(code);
    } */
  }
}


