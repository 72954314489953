import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NewPasswordRequireComponent } from './components/new-password-require/new-password-require.component';
import { MfaVerificationComponent } from './components/mfa-verification/mfa-verification.component';
import { authGuard } from './guards/auth.guard'; 

const routes: Routes = [
  { 
    path: 'login', 
    component: LoginComponent, 
  },
  { 
    path: 'home',
    component: HomeComponent, 
    canActivate: [authGuard]
  },
  { 
    path: 'newPasswordRequire', 
    component: NewPasswordRequireComponent,
  },
  { 
    path: 'mfaverification', 
    component: MfaVerificationComponent, 
    canActivate: [authGuard]
  },
  { 
    path: '', redirectTo:'/login', pathMatch:'full' 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
