import { Injectable } from "@angular/core";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { environment } from 'src/environments/environment';
import { Router } from "@angular/router";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CognitoService {
  userPool: any;
  cognitoUser: any;
  username: string = "";
  token: string = "";

  private passwordChangeSubject = new Subject<boolean>();
  passwordChange$ = this.passwordChangeSubject.asObservable();

  constructor(private router: Router) {}

  async login(emailaddress: any, password: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let authenticationDetails = new AuthenticationDetails({
        Username: emailaddress,
        Password: password,
      });

      let poolData = {
        UserPoolId: environment.cognitoUserPoolId,
        ClientId: environment.cognitoAppClientId,
      };

      // Configurar el usuario y el grupo de usuarios
      this.userPool = new CognitoUserPool(poolData);
      let userData = { Username: emailaddress, Pool: this.userPool };
      this.cognitoUser = new CognitoUser(userData);

      // Autenticar el usuario
      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result: any) => {
          this.router.navigate(["/home"]);
          this.token = result.getIdToken().getJwtToken();
          console.log("Success Results : ", result);
          resolve(true); 
        },     
        // First time login attempt
        newPasswordRequired: () => {
          this.router.navigate(["/newPasswordRequire"]);
          resolve(false); 
        },
        onFailure: (error: any) => {
          console.log("error", error);
          resolve(false); 
        },
      });
    });
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) {
    if (newPassword === confirmPassword) {
      this.cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: () => {
            console.log("Reset Success");
            this.passwordChangeSubject.next(true);
          },
          onFailure: () => {
            this.passwordChangeSubject.next(false);
            console.log("Reset Fail");
          },
        }
      );
    } else {
      console.log("Password didn't Match");
    }
  }

  // Logout 
  logOut() {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };
    this.userPool = new CognitoUserPool(poolData);
    this.cognitoUser = this.userPool.getCurrentUser();
    if (this.cognitoUser) {
      this.cognitoUser.signOut();
      this.router.navigate(["login"]);
    }
  }
}
