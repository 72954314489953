import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CognitoService } from 'src/app/cognito.service';  

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  emailaddress: string = '';
  password: string = '';
  showPassword: any;
  showErrorAlert: boolean = false;
  successLogin: boolean = false;

  constructor(private authService: CognitoService) { 
  }

  async onSignIn(form: NgForm) {
    if (form.valid) {
      try {
        const success = await this.authService.login(this.emailaddress, this.password);
        if (!success) {
          this.showErrorAlert = true; 
        } else {
          this.successLogin = true;
        }
      } catch (error) {
        console.error("Error during login:", error);
        this.showErrorAlert = true; 
      }
    }
  }
}


