<div class="relative min-h-screen p-4 flex flex-col sm:justify-center items-center bg-gray-300 ">
<body class="antialiased">
  <div class="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
    <h1 class="text-3xl font-medium">Cambio de contraseña requerido</h1>
    <p class="text-slate-500">Complete el formulario para cambiar la contraseña</p>

    <form (submit)="confirmPasswordReset()" class="my-10">
      <div class="flex flex-col space-y-5">


        <label for="newPassword" class="relative">
         <div class=" flex relative"> 
          <p class="font-medium text-slate-700  w-1/3 pb-2">Nueva contraseña</p> 
          <div class="relative w-2/3 mt-1 ">
            <button class=" text-white active:bg-custom-blue font-bold uppercase text-sm px-2 rounded   outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="togglePasswordRequirements()">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16"><path fill="#6BBECD" d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.93 4.588l-2.29.287l-.082.38l.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319c.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246c-.275 0-.375-.193-.304-.533zM8 5.5a1 1 0 1 0 0-2a1 1 0 0 0 0 2"/></svg></button>
            <div *ngIf="passwordRequirementsVisible" class="absolute z-50 bg-custom-blue border border-blue-300 rounded-lg shadow-lg text-white p-3 top-0 left-0 mt-10">
              <div class="bg-custom-blue text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100  rounded-t-lg">
                Requisitos de la contraseña
              </div>
              <div class="text-white p-3">
                <ul>
                  <li class="font-semibold">Debe incluir al menos:</li>
                  <li>8 caracteres.</li>
                  <li>1 letra minúscula.</li>
                  <li>1 letra mayúscula.</li>
                  <li>1 número.</li>
                  <li>1 carácter especial !#$%^&*(),.?":|</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="relative">
          <input [type]="showNewPassword ? 'text' : 'password'" id="newPassword" name="newPassword" class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Ingrese la nueva contraseña" [(ngModel)]="newPassword" style="padding-left: 15px;" (input)="checkInputs()">
          <button type="button" class="absolute top-0 end-0 p-3.5 rounded-e-md" (click)="showNewPassword = !showNewPassword">
              <svg class="flex-shrink-0 size-3.5 text-gray-400" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path *ngIf="showNewPassword" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                  <path *ngIf="showNewPassword" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                  <path *ngIf="showNewPassword" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                  <line *ngIf="showNewPassword" x1="2" x2="22" y1="2" y2="22"></line>
                  <path *ngIf="!showNewPassword" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                  <circle *ngIf="!showNewPassword" cx="12" cy="12" r="3"></circle>
              </svg>
          </button>
      </div>
        </label>
        
      <label for="confirmPassword" class="relative">
          <p class="font-medium text-slate-700 pb-2">Confirmar nueva contraseña</p>
          <div class="relative">
              <input [type]="showConfirmPassword ? 'text' : 'password'" id="confirmPassword" name="confirmPassword" class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Confirme la nueva contraseña" [(ngModel)]="confirmPassword" style="padding-left: 15px;" (input)="checkInputs()">
              <button type="button" class="absolute top-0 end-0 p-3.5 rounded-e-md" (click)="showConfirmPassword = !showConfirmPassword">
                  <svg class="flex-shrink-0 size-3.5 text-gray-400" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path *ngIf="showConfirmPassword" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                      <path *ngIf="showConfirmPassword" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                      <path *ngIf="showConfirmPassword" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                      <line *ngIf="showConfirmPassword" x1="2" x2="22" y1="2" y2="22"></line>
                      <path *ngIf="!showConfirmPassword" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                      <circle *ngIf="!showConfirmPassword" cx="12" cy="12" r="3"></circle>
                  </svg>
              </button>
          </div>
          <div class="flex items-center mt-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" *ngIf="secondPasswordTyped && passwordsMatch() && newPassword && confirmPassword">
                  <path fill="#6BBECD" d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4zM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 256 256" *ngIf="secondPasswordTyped && (!passwordsMatch() || !newPassword || !confirmPassword)">
                  <path fill="#e11d48" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m37.66 130.34a8 8 0 0 1-11.32 11.32L128 139.31l-26.34 26.35a8 8 0 0 1-11.32-11.32L116.69 128l-26.35-26.34a8 8 0 0 1 11.32-11.32L128 116.69l26.34-26.35a8 8 0 0 1 11.32 11.32L139.31 128Z"/>
              </svg>
          </div>
      </label>
      <div class="flex absolute items-center justify-center bg-gray-100">
        <div class="fixed top-0 left-0 w-full h-full flex items-center justify-center "*ngIf="successModal" >
          <div class="rounded-lg bg-gray-50 px-16 py-14">
            <div class="flex justify-center">
              <div class="rounded-full bg-green-200 p-6">
                <div class="flex h-16 w-16 items-center justify-center rounded-full bg-green-500 p-4">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-8 w-8 text-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                </div>
              </div>
            </div>

            <p class="w-[230px] my-4 text-center font-semibold text-gray-700">Su contraseña ha sido cambiada con éxito.</p>
            <button class="mx-auto mt-10 block rounded-xl border-4 border-transparent bg-custom-blue px-6 py-3 text-center text-base font-medium text-white text-md outline-8 hover:outline hover:duration-300" (click)="redirectToLoginPage()">Iniciar Sesión</button>
          </div>
        </div>
      </div>
      
      
      <div class="font-regular relative block w-full rounded-lg bg-rose-500 p-3 mt-4 text-base leading-5 text-white opacity-100" *ngIf="passwordError">
        <div class="mr-12">La contraseña no cumple con los requisitos</div>
        <div class="absolute top-2.5 right-3 w-max rounded-lg transition-all hover:bg-white hover:bg-opacity-20">
          <button role="button" class="w-max rounded-lg p-1" (click)="passwordError = false">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg> 
          </button>
        </div>
      </div>
      <div class="font-regular relative block w-full rounded-lg bg-rose-500 p-3 mt-4 text-base leading-5 text-white opacity-100" *ngIf="unMatchPassword">
        <div class="mr-12">Las contraseñas no coinciden</div>
        <div class="absolute top-2.5 right-3 w-max rounded-lg transition-all hover:bg-white hover:bg-opacity-20">
          <button role="button" class="w-max rounded-lg p-1" (click)="unMatchPassword = false">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg> 
          </button>
        </div>
      </div>
      <div class="font-regular relative block w-full rounded-lg bg-rose-500 p-3 mt-4 text-base leading-5 text-white text-center opacity-100" *ngIf="validateError">
        <div class="mr-12">¡Error! no se pudo cambiar la contraseña.</div>
        <div class="absolute top-2.5 right-3 w-max rounded-lg transition-all hover:bg-white hover:bg-opacity-20">
          <button role="button" class="w-max rounded-lg p-1" (click)="validateError = false">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg> 
          </button>
        </div>
      </div>
        <button type="submit" class="w-full py-3 font-medium text-white bg-custom-red hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
          </svg>
          <span>Cambiar Contraseña</span>
        </button>
      </div>
    </form>
  </div>
</body>
</div>